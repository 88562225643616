export default function formatImagePath(imagePath) {
    if (!imagePath || typeof imagePath !== 'string') {
        return ''
    }
    
    // if using a full hosted file path starting with 'http', just return that value
    if (imagePath.startsWith('http') || imagePath.startsWith('data:image')) {
        return imagePath
    }
    
    // only get the path from within public/images (trim everything before the last occurence of 'images/')
    const subStr = 'images/'
    if (imagePath.includes(subStr)){
        return imagePath.substring(imagePath.indexOf(subStr))
    }
    return imagePath
}